<template>
  <div
    class="main"
    :style="{
      display: 'flex',
      width: '100%',
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
    }"
  >
    <div class="box">
      <div class="box-container">
        <div class="box-logo">
          <img src="@/assets/images/logo.svg" />
        </div>
        <div class="box-router-wrapper">
          <router-view> </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import image from "@/assets/images/bg.png";
import '@/assets/scss/app.scss'
import '@/assets/scss/app.tablet.scss'
import '@/assets/scss/app.mobile.scss'

export default {
  data() {
    return {
      image: image,
    };
  },
   watch:{
    $route (){
      this.$nextTick(() => {
        window.$('input:visible:first').focus()
      })
    }
  },
  mounted(){
    window.$('input:visible:first').focus()
  }
};
</script>

<style lang="scss" scoped>
.main{
 height: 100vh;
}
.box {
  padding: 35px 0;
  //text-align: center;
  width: 450px;
  margin: auto;

  .box-logo {
    height: 65px;
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    img {
      margin: auto;
      width: auto;
      height: 100%;
    }
  }

  .box-container {
    padding: 30px 30px 0 30px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 8px;
  }

  .box-router-wrapper{
    display: flex;
  }
}

// MOBILE
@media screen and (max-width: 500px) {
  .main{
  //  height: 100vh;
   max-height: 100%;
   
  //  
  }
  .box {
    padding: 0;
    //text-align: center;
    width: 100%;
    margin: 0;
    position: relative;

    .box-logo {
      width: 100%;
      height: 65px;
      max-height: 65px;
      margin-bottom: 0;
      margin-top: 35px;
      padding: 10px 0;
      background: transparent;
      display: flex;
      img {
        margin: auto;
        width: auto;
      }
    }

    .box-container {
      min-height: calc(100% - 105px);
      flex: 1;
      /* max-height: calc(100% - 105px); */
      margin-top: 0px;
      display: flex;
      padding: 20px 20px 0px 20px;
      background: transparent;
      border: 0;
      border-radius: 8px;
      //display: -webkit-box;
    }
  }
}
</style>

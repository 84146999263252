var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main",style:({
    display: 'flex',
    width: '100%',
    backgroundImage: `url(${_vm.image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  })},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-container"},[_vm._m(0),_c('div',{staticClass:"box-router-wrapper"},[_c('router-view')],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg")}})])
}]

export { render, staticRenderFns }